$fragment_width: 25%;

.bd-example{
    width: 80%;
    margin: 0px auto;
}

.show-neighbors {
  overflow: hidden;

  /* hide clones of neighboring slides on mobile */
  .item__third:first-child, 
  .item__third:last-child {
    display: none;
  }

  @media (min-width: 500px) {
    .carousel-indicators {
      margin-right: $fragment_width;
      margin-left: $fragment_width;      
    }

    .carousel-control-prev, 
    .carousel-control-next {
      background: rgba(255, 255, 255, 0.3);
      width: $fragment_width;
      z-index: 11;  /* .carousel-caption has z-index 10 */
    }

    .carousel-inner {
      width: (100% - 2 * $fragment_width) * 3;
      left: 3 * $fragment_width - 100%;
    }

    /* slides receive classes only while moving */
    .carousel-item-next:not(.carousel-item-left),
    .carousel-item-right.active {
      -webkit-transform: translate3d(33%, 0, 0);
      transform: translate3d(33%, 0, 0);
    }
    .carousel-item-prev:not(.carousel-item-right),
    .carousel-item-left.active {
      -webkit-transform: translate3d(-33%, 0, 0);
      transform: translate3d(-33%, 0, 0);
    }

    /* now the content of the current slide occupies only a third of it between the clones of two adjacent slides */
    .item__third {
      display: block !important;
      float: left;
      position: relative;  /* captions can now be added */
      width: 33.33333333%;
      border: 1px solid #fff;
    }
  }
}


.item__third{
    // border: 1px solid #fff;
    // height: 400px;
    // background-color: blue;
    display: flex;
    justify-content: space-between;
}

// .thirdchild, .secondchild, .firstchild{
//     border: 1px solid !important;
//     img{
//         height: 100%;
//     }
// }

.secondchild{    
    display: flex;
    justify-content: space-between;
    .img{
      width:240px !important;        
        // border: 1px solid blue;
        img{
            width: 100%;                
        }
    }
    .content{        
        background-color: #fff;
        padding-left: 30px;
        color: black;
        
    }  
}


// .slider{
//   position: relative;
// }

.carousel-indicators li.acive {
  box-sizing: content-box;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #808080;
  background-clip: padding-box;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  // opacity: .5;
  transition: opacity .6s ease;
  // position: absolute;
  // bottom: -40px;
}

.carousel-indicators li {
  box-sizing: content-box;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #000;
  background-clip: padding-box;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  // opacity: .5;
  transition: opacity .6s ease;
  // position: absolute;
  // bottom: -40px;
}

.carousel-inner{  
  .quotes{
    font-size: 40px;
    font-weight: bold;
    color:#a1a1a1;
    
  }
  .speakercontent{
    font-size: 16px;
    font-weight: 500;
  }
  .speakername{    
    font-size: 18px;
    font-weight: 600;      
  }
  .speakerdesignation{
    font-size: 13px; 
    font-weight: 500;
  }
}

.carousel-control-prev{
  margin-left: -111px;
}

.carousel-control-next{
  margin-right: -111px;
}


@media screen and (max-width: 500px){
    .bd-example{
        width: 99%;
        margin: 0px auto;
    }
}

