@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
/* Montserrat */
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
/* 'Josefin Sans', sans-serif */
@import url("https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,100..700;1,100..700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");
/*  */
@import url("https://fonts.googleapis.com/css2?family=Bungee&display=swap");

/* Fonts */
@font-face {
  font-family: "Montserrat-Regular";
  src: url("../fonts/static/Montserrat-Regular.ttf") format("ttf"),
    url("../fonts/static/Montserrat-Regular.ttf") format("ttf"),
    url("../fonts/static/Montserrat-Regular.ttf") format("ttf");
}

/* styles */
* {
  padding: 0px;
  margin: 0px;
  box-sizing: border-box;
  font-family: "Montserrat", sans-serif !important;
  font-family: "Montserrat-Regular";
}
body {
  background-color: #000;
}
p {
  margin-bottom: 0px;
}
.indianJoy__wrapper {
  width: 100%;
  height: auto;
  background-color: #000;
  position: fixed;
  top: 0px;
  z-index: 99;
}
.indiaJoy__container {
  width: 95% !important;
  height: auto;
  margin: auto;
  background-color: #000;
  border-bottom: 1px solid #1b1a1a;
}
.indiaJoy__container .navbar-brand {
  color: #fff !important;
}
.indiaJoy__container .navbar-brand > img {
  width: 176.4px;
  height: 45.66px;
  left: 82px;
  top: 31px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))
    drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}
.indiaJoy__container .navbar-nav .nav-item .nav-link {
  color: #fff !important;
  font-style: normal;
  font-size: 14px;
  line-height: 17px;
  font-weight: 500;
  font-style: normal;
  padding: 11px;
  text-transform: uppercase;
}
.navbar-toggler {
  background: linear-gradient(90deg, #fa2290 0%, #ffd100 100%);
  padding: 6px;
  width: 43px;
  height: 42px;
}
.indiaJoy_register_btn {
  background: linear-gradient(90deg, #fa2290 0%, #ffd100 100%);
  width: 180px;
  height: 44px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  color: #ffffff;
  border: 0px;
  border-radius: 2px;
  margin-left: 12px;
  text-transform: uppercase;
}
.indiaJoy_register_btn:hover {
  background: linear-gradient(to right, #feb47b, #fe31b8);
}
.indiaJoy__rightPanel__container__menubar {
  display: none;
}
.speakers-background {
  background-image: url("../images/Mask\ group\ \(1\).webp");
  background-size: cover;
  background-color: #000;
  background-position: center;
  object-fit: contain;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  align-items: center;
}
.speaker-footer {
  color: #f7d047;
  text-decoration-line: underline;
  display: flex;
  align-items: center;
  font: bold;
  font-weight: bold;
  font-size: 20px;
  justify-content: space-around;
  text-align: center;
  cursor: pointer;
  padding-bottom: 40px;
  padding-top: 30px;
}
.speakers-content {
  text-align: left;
  color: #ffffff;
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: space-between;
  align-items: center;
  font-size: 20px;
  text-align: left;
  width: 85%;
  height: auto;
  margin: auto;
  padding: 50px 0px;
}

.speakers__content__container {
  width: 50%;
}

.speakers__content__container span {
  -webkit-text-stroke: 1px #fff;
  color: transparent;
  background-color: #000;
}

.animation__for__profile img {
  transition: transform 0.2s ease-out;
  padding: 4px;
  border-radius: 50%;
}

.animation__for__profile1 img {
  transition: transform 0.2s ease-out;
  padding: 4px;
}
.speaker__content__section {
  width: 45%;
}
.speakers__content__container h2 {
  font-weight: 900;
  font-size: 50px;
  line-height: 51px;
  background-image: linear-gradient(
    132.05deg,
    #ffd300 4.23%,
    #fe2fba 47.3%,
    #030068 100.03%
  );
  background-color: #ffd300;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  text-transform: uppercase;
}

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background: linear-gradient(#fe31b8, #feb47b);
}

::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 6px;
  border: 3px solid #555;
}

.featured-title {
  font-size: 4em;
  font-weight: bold;
  background: -webkit-linear-gradient(#fe31b8, #feb47b);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.speakers-title {
  font-size: 5em;
  font-weight: bold;
  background: -webkit-linear-gradient(#f7b733, #fe31b8);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.footer-title {
  font-weight: 900;
  font-size: 45px;
  line-height: 51px;
  background: -webkit-linear-gradient(#f7b733, #fe31b8);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display: flex;
  justify-content: center;
}

.indiaJoy__footer__container {
  width: 90%;
  height: auto;
  margin: auto;
  margin: 70px 0px 10px 0px;
}

.subscribe_input {
  /* background: #000; */
  /* color: #d3d3d3; */
  color: #fff;
  font-size: 16px;
  /* padding: 0.5px; */
  border-radius: 50px;
  /* width: 100%; */
  width: 350px;
  height: 58px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  border: 2px solid transparent;
  /* background-image: repeating-linear-gradient(-5deg, #f7b733, #fe31b8, #290774); */
  background-image: linear-gradient(#000, #000),
    repeating-linear-gradient(-5deg, #f7b733, #fe31b8, #290774);
  background-origin: border-box;
  background-clip: content-box, border-box;
}

.emailInput {
  /* background: #000;  
  color:#fff;
  font-size: 16px;
  padding: 0.5px;
  border-radius: 50px;
  width: 100%;
  width: 395px;
  height: 55px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  border: 2px solid transparent;
  background-image:
    repeating-linear-gradient(-5deg, #f7b733, #fe31b8, #290774);
  background-origin: border-box;
  background-clip: content-box, border-box; */
  background-color: transparent;
  width: 98%;
  /* height: 25px; */
  display: inline-block;
  border-radius: 30px;
  height: 47px;
  color: #000;
  margin-left: 3px;
  margin-top: 4px;
  padding-left: 20px;
  border: 0px;
}

.emailInput:focus {
  outline: 0 !important;
  border: 0 !important;
}

/* .emailInput::placeholder {
  color: #555;
  padding-left: 30px;
} */

.description {
  font-size: 18px;
  margin: 20px 0;
}

.indiaJoy__meet__container {
  margin: 0px 0px 25px 0px !important;
}

.explore-button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  width: 187px;
  height: 51px;
  font-size: 15px;
  border: 0px;
  border-radius: 2px;
  font-weight: 500;
  background: linear-gradient(90deg, #fe2fba 0%, #ffd300 100%);
  text-transform: uppercase;
  color: #fff;
}
.indiaJoy__global__container {
  width: 90%;
  height: auto;
  margin: auto;
  color: #fff;
  font-size: 17.5px;
  margin-top: 70px;
  margin-bottom: 50px;
}
.indiaJoy__global__section p:last-child {
  padding-top: 16px;
}
.indiaJoy__global__section,
.indiaJoy__global__details,
.indiaJoy__global__itemList,
.indiaJoy__global__applySection {
  margin-bottom: 30px;
}
.indiaJoy__global__details h2,
.indiaJoy__global__itemList h2,
.indiaJoy__global__applySection h2 {
  text-transform: uppercase;
  font-size: 28px;
  font-weight: 700;
  padding-bottom: 10px;
}
.indiaJoy__global__container button {
  padding: 14px 63px;
  background: linear-gradient(269.54deg, #ffd100 -3.34%, #fa2290 77.84%);
  color: #fff;
  border: 0px;
  border-radius: 4px;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 16px;
}
.indiaJoy__global__container button:hover {
  background: linear-gradient(to right, #feb47b, #fe31b8);
}
.indiaJoy__global__container button a {
  text-decoration: none;
  color: #fff;
}
.indiaJoy__global__section h1 {
  font-size: 40px;
  line-height: 44px;
  background-image: linear-gradient(#ffd300, #fe2fba 49.3%, #030068 93%);
  background-color: #ffd300;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-transform: uppercase;
  font-weight: 800;
  margin-bottom: 20px;
}
.secondchild {
  width: 100%;
}

.explore-button1 {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  width: 250px;
  height: 51px;
  font-size: 15px;
  border: 0px;
  border-radius: 2px;
  font-weight: 500;
  background: linear-gradient(90deg, #fe2fba 0%, #ffd300 100%);
  text-transform: uppercase;
  color: #fff;
  margin-bottom: 40px;
}

.explore-button:hover,
.explore-button1:hover {
  background: linear-gradient(to right, #feb47b, #fe31b8);
}

.subscribe-button {
  font-size: 14px;
  padding: 15px 30px;
  margin-left: 20px;
  border-radius: 25px;
  background: linear-gradient(to right, #fe31b8, #feb47b);
  color: white;
  cursor: pointer;
  height: -moz-fit-content;
  height: fit-content;
  font-weight: 700;
}

.indiaJoy__rightPanel__menubar {
  display: flex;
  width: 100%;
}

.indiaJoy__rightPanel__section__menu {
  display: none;
}
.indiaJoy__global__bannerSection {
  position: relative; /* Needed for the pseudo-element positioning */
  background-image: url("../images/banner_global.svg");
  background-size: cover;
  background-position: center;
  object-fit: contain;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  align-items: center;
  margin-top: 72px;
  padding: 50px 30px;
}

/* Pseudo-element for the overlay */
.indiaJoy__global__bannerSection::before {
  content: ""; /* Required for pseudo-element */
  position: absolute; /* Positioning it absolutely within the parent */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.53); /* Black background with 53% opacity */
  pointer-events: none; /* Allows clicks to pass through */
  z-index: 1; /* Ensures overlay is above the background but below content */
}

/* Ensure content inside the banner section is above the overlay */
.indiaJoy__global__bannerSection > * {
  position: relative;
  z-index: 2;
}

.indiaJoy__global__bannerSection img {
  width: 189px;
  height: 300px;
}
.subscribe {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.coming-soon-button {
  font-size: 15px;
  padding: 10px 25px;
  border-radius: 25px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  background: linear-gradient(to right, #fe31b8, #feb47b);
  color: white;
  cursor: pointer;
  margin: 1px 0px 25px 0px;
  border: 0px;
}

.coming-soon-button a {
  color: #fff;
  outline: 0px;
  text-decoration: none;
}

.coming-soon-button:hover {
  background: linear-gradient(to right, #feb47b, #fe31b8);
}

.venue {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.venueCard {
  display: flex;
  border: 1px solid #ccc;
  border-radius: 10px;
  margin: 10px;
  background-color: #000000;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  align-content: center;
  justify-content: space-between;
  color: #ffffff;
  width: 45%;
}

.venue-inner-card {
  display: flex;
  align-items: center;
  margin-right: 20px;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: space-around;
  justify-content: center;
  padding: 25px;
}

.venue-inner-card-location {
  display: flex;
  align-items: center;
  margin-right: 20px;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: space-around;
  justify-content: flex-end;
}

.indiaJoy__novotelSection p {
  font-size: 16px;
  padding-left: 8px;
  padding-top: 8px;
  text-transform: uppercase;
}

.locationLogo {
  width: 65px;
  height: 84px;
  margin-right: 21px;
}

.locationImg {
  width: 297px;
  height: 200px;
  border-radius: 10px;
  position: relative;
  right: -20px;
}

.viewMap-button {
  padding: 4px 8px;
  background-color: transparent;
  color: #fff;
  border-radius: 20px;
  border: 1px solid #fe2fba;
  margin: 4px;
  font-size: 14px;
  white-space: nowrap;
}

.speakers-section {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  width: 89%;
  margin: auto;
  height: auto;
  padding: 30px 0px;
}

.speaker-card {
  text-align: center;
  border-radius: 10px;
  padding: 30px 10px;
  width: 315px;
  position: relative;
  margin: 15px 0px;
}

.speaker-card .speaker_link {
  margin: 20px 0px;
}

.speaker-card .speaker_link a {
  position: absolute;
  display: block;
  bottom: 0px;
  left: 45%;
}

.speaker-image {
  border-radius: 50%;
  margin-bottom: 20px;
}

.indiaJoy__join__container {
  width: 85%;
  height: auto;
  margin: auto;
  padding: 70px 0px 0px 0px;
}

.indiaJoy__tribeSection {
  text-align: center;
}

.indiaJoy__tribeSection h2 {
  font-weight: 900;
  font-size: 40px;
  line-height: 44px;
  background: linear-gradient(90deg, #ffd300 -61.33%, #fe2fba 147.42%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  text-transform: uppercase;
}

.agenda-content {
  display: flex;
  justify-content: space-between;
  /* border: 1px solid #fff; */
}

.indiaJoy__leftSection {
  width: 48%;
  /* padding-top: 73px; */
  /* border: 1px solid yellow; */
  margin-top: 50px;
}

.indiaJoy__secondSection {
  width: 50%;
  /* border: 1px solid yellowgreen;     */
}

.indiaJoy__leftSection h2 {
  font-weight: 700;
  font-size: 38px;
  line-height: 51px;
  text-transform: uppercase;
  color: #ffffff;
}

.indiaJoy__leftSection p {
  font-size: 18px;
  margin: 20px 0px;
  line-height: 28px;
  text-align: justify;
}

.speaker-name {
  font-size: 24px;
  font-weight: bold;
  color: #ffffff;
  white-space: nowrap;
  align-self: top;
  text-align: center;
}

.speaker-title {
  color: #ffffff;
  margin-bottom: 20px;
  font-size: 16px;
  align-self: top;
  text-align: center;
}

.speaker-links {
  display: flex;
  justify-content: center;
}

.speaker-icon {
  width: 30px;
  height: 30px;
  margin: 0 10px;
  color: #ffffff;
}

.background-container {
  background: #000;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  align-items: center;
}

.agenda-title {
  font-size: 40px;
  line-height: 44px;
  font-weight: bold;
  background-image: linear-gradient(
    132.05deg,
    #ffd300 4.23%,
    #fe2fba 47.3%,
    #030068 100.03%
  );
  background-color: #ffd300;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display: flex;
  justify-content: center;
  position: relative;
  top: -4px;
  padding-bottom: 30px;
  font-weight: 800;
}

.agenda-title__contact {
  font-size: 40px;
  line-height: 44px;
  font-weight: bold;
  background-image: linear-gradient(
    132.05deg,
    #ffd300 4.23%,
    #fe2fba 47.3%,
    #030068 100.03%
  );
  background-color: #ffd300;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display: flex;
  position: relative;
  top: -4px;
  padding-bottom: 30px;
}

.agenda-content {
  text-align: left;
  padding: 90px 0px;
  /* padding-top: 40px; */
  color: #ffffff;
  font-size: 1.2em;
  margin: 20px 0;
  /* border: 1px solid; */
}

.subscribe-content {
  display: flex;
  justify-content: center;
  text-align: center;
  padding: 30px 0px 10px 0px;
  color: #ffffff;
  font-size: 16px;
  margin-top: -30px;
}

.multiline {
  white-space: pre-wrap;
}

.address-content {
  text-align: center;
  width: 50%;
  margin: auto;
  color: #ffffff;
  font-size: 18px;
  padding: 38px 0px 10px 0px;
  margin-top: -50px;
}

.footer-content {
  width: 100%;
  text-align: center;
  padding: 20px 0px;
  color: #ffffff;
  font-size: 16px;
}

.imgSupporter {
  padding: 1em;
}

.indiaJoy__rightPanel__footer {
  display: flex;
  justify-content: center;
}

.indiaJoy__slider__img {
  width: 250px;
  height: auto;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  border: 5px solid transparent;
  background-image: linear-gradient(#000, #000),
    repeating-linear-gradient(-5deg, #f7b733, #fe31b8, #290774);
  background-origin: border-box;
  background-clip: content-box, border-box;
}

.indiaJoy__slider__img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.indiaJoy__content__details {
  padding: 20px;
}

.indiaJoy__desName {
  padding-top: 30px;
}

.secondchild {
  background-color: #fff;
}

.imgSupporter img {
  width: 138.47px;
  height: 77.12px;
  object-fit: cover;
}

.logos {
  display: flex;
  flex-wrap: wrap;
  width: 90%;
  height: auto;
  margin: auto;
  justify-content: center;
}

.telanganaLogo img {
  width: 200px;
}

.groupImage {
  width: 678px;
  height: 460px;
  object-fit: cover;
}

.coming-soon-image {
  width: 250px;
  height: 200px;
  object-fit: cover;
}

.indiaJoy__bgforcards {
  background-image: url("../images/Mask\ group.webp");
  background-repeat: repeat;
  background-size: cover;
  background-position: center center;
  background-color: #000;
  width: 100%;
}

.indiaJoy__innovation__container {
  background-image: url("../images/bg.webp");
  background-repeat: repeat;
  background-size: cover;
  background-position: center center;
  background-color: #000;
  width: 100%;
}

.agendaFrame {
  background-image: url("../images/Frame.webp");
  background-repeat: repeat;
  background-size: cover;
  background-position: center center;
  background-color: #000;
  width: 100%;
}

.slider-wrapper {
  width: 80%;
  overflow: hidden;
}

.slider-container {
  width: 100%;
  max-width: 100%;
  overflow: hidden;
}

.slide {
  display: flex;
  justify-content: center;
  align-items: center;
}

.slide img {
  width: 100%;
  height: auto;
}

.custom-dots {
  position: absolute;
  bottom: -25px;
  width: 100%;
  display: flex !important;
  justify-content: center;
  list-style: none;
  padding: 0;
  margin: 0;
}

.custom-dots li {
  margin: 0 5px;
}

.custom-dot {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  border: 2px solid transparent;
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background: repeating-linear-gradient(-5deg, #f7b733, #fe31b8, #290774);
}

.custom-dots li.slick-active .custom-dot {
  background: #290774;
  border: none;
}

.custom-dots li .custom-dot:before {
  content: "";
  width: 100%;
  height: 100%;
  display: block;
  border-radius: 50%;
  background: repeating-linear-gradient(-5deg, #f7b733, #fe31b8, #290774);
}

.purchaseTicketFrame {
  background-image: url("../images/purchaseTicketBg.webp");
  background-repeat: repeat;
  background-size: cover;
  background-position: center center;
  background-color: #000;
  width: 100%;
  padding-bottom: 80px;
}

.indiaJoy__experience__container {
  padding: 90px 0px 30px 0px;
  width: 100%;
}

.designHeader {
  margin: 100px 0px 40px 0px;
}

.purchaseTicketCardBg {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 75%;
  height: auto;
  margin: auto;
}

.cardBg {
  display: flex;
  justify-content: center;
  width: 90%;
  padding: 90px 0px 100px 0px;
  flex-wrap: wrap;
  margin: auto;
}

.coming-soon-card:nth-child(1) .coming-soon-button {
  margin-top: 30px;
}

.purchaseTicketCard {
  display: flex;
  justify-content: space-between;
  background: #000;
  padding: 0.4 em;
  border-radius: 15px;
  max-width: 100%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  border: 5px solid transparent;
  background-image: linear-gradient(#000, #000),
    repeating-linear-gradient(-5deg, #f7b733, #fe31b8, #290774);
  background-origin: border-box;
  background-clip: content-box, border-box;
  margin: 80px 0px 60px 0px;
  overflow: hidden;
}

.scroll-to-top {
  position: fixed;
  bottom: 16px;
  right: 21px;
}

.scroll-to-top button {
  border: 1px solid transparent;
  background-color: transparent;
}

.scroll-to-top img {
  width: 45px;
  height: 45px;
  transform: rotate(-185deg);
}

.coming-soon-card {
  border-radius: 15px;
  max-width: 100%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  border: 5px solid transparent;
  background-image: linear-gradient(#000, #000),
    repeating-linear-gradient(-5deg, #f7b733, #fe31b8, #290774);
  background-origin: border-box;
  background-clip: content-box, border-box;
  margin: 20px;
  width: 400px;
  overflow: hidden;
  position: relative;
}

.description__card {
  font-size: 17px;
  margin: 20px;
  text-align: center;
  line-height: 29px;
}

.content {
  flex: 1;
  color: #fff;
  padding: 2em;
}

.coming-soon-content {
  display: flex;
  color: #fff;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: space-around;
  padding: 0.1em;
}

.buyTickets {
  flex: 1 1;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 435px;
}

.buyTickets img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.styled-hr {
  border: 1px solid #343333;
  background: white;
  margin: 30px 0px 0px 0px;
}

.footer-logos {
  width: 91px;
  height: 68px;
  cursor: pointer;
}

.subscribe {
  display: block;
}

.email-error {
  color: red;
  font-size: 16px;
  text-align: left;
  padding: 10px 10px 0px 20px;
}

.email-success {
  color: yellow;
  font-size: 16px;
  text-align: left;
  padding: 10px 10px 0px 20px;
}

.indiaJoy__banner__container {
  height: 570px;
  margin-top: 77px;
}

.indiaJoy__banner__section {
  position: relative;
  overflow: hidden;
}

.video-overlay {
  position: absolute;
  top: -4px;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.video-overlay:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("../images/bannerOverlay.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

.indiaJoy__banner__detailsSection {
  position: absolute;
  left: 171px;
  top: 88px;
  display: flex;
  justify-content: space-between;
  width: 91%;
  align-items: end;
}

.indiaJoy__banner__details {
  width: 100%;
}

.indiaJoy__banner__details > .customStyleCon  > img {
  width: 210px;
  height: 50px;
  margin-right: 8px !important
}
.customStyleCon{
  display: flex;
}

.customStyleCon--remove--flex{
  display: unset;
  color: #fff;
}

.indiaJoy__banner__content {
  font-style: normal;
  font-weight: 600;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 36px;
  line-height: 41px;
  color: #ffc20d;
}

.indiaJoy__banner__subText {
  font-style: normal;
  font-weight: 700;
  font-size: 21px;
  line-height: 29px;
  color: #fc2fb9;
}

.indiaJoy__banner__subText > span {
  font-style: normal;
  font-weight: 900;
  font-size: 40px !important;
  line-height: 49px;
  text-align: center;
  padding-right: 6px;
  color: #fc2fb9;
}

.indiaJoy__banner__text {
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  line-height: 22px;
  color: #fc2fb9;
}

.indiaJoy__banner__btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px;
  width: 187px;
  height: 51px;
  font-size: 15px;
  border: 0px;
  border-radius: 2px;
  font-weight: 500;
  background: linear-gradient(90deg, #fe2fba 0%, #ffd300 100%);
  text-transform: uppercase;
  color: #fff;
  margin: 20px 0px 0px 0px;
  font-weight: 600;
}

.indiaJoy__banner__btn:hover {
  background: linear-gradient(to right, #feb47b, #fe31b8);
}

.indiaJoy__banner__subText {
  padding-top: 20px;
}

.indiaJoy__banner__text {
  padding-top: 2px;
}

.indiaJoy__count__wrapper {
  position: relative;
}

.indiaJoy__count__container {
  background-image: url("../images/Vector.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  position: absolute;
  width: 100%;
  height: 461px;
  top: -160px;
  object-fit: cover;
}

.indiaJoy__count__section {
  width: 80%;
  height: auto;
  margin: auto;
  display: flex;
  position: relative;
  top: 180px;
  color: #fff;
  justify-content: space-between;
  flex-wrap: wrap;
}

.indiaJoy__mainSection__wrapper {
  background-image: url("../images/Frame.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  background-color: #000;
  width: 100%;
  height: 3720px; /* old */
  /* height: 2758px; new */
}

.indiaJoy__mainSection__container {
  width: 75%;
  height: auto;
  margin: auto;
  position: relative;
  top: 300px;
}

.indiaJoy__viewMore {
  display: flex;
  justify-content: center;
  padding: 57px 0px;
  cursor: pointer;
}

.indiaJoy__bannerInfo {
  width: 100%;
  height: 686px;
  margin-left: 247px;
}

.indiaJoy__mainSection__container .indiaJoy__banner__btn {
  margin: auto;
}

.indiaJoy__mainSection__section {
  display: flex;
  justify-content: space-between;
  margin-bottom: 45px;
}

.indiaJoy__mainSection__item p {
  font-size: 20px;
  color: #fff;
  text-transform: uppercase;
  font-weight: 700;
  padding-top: 14px;
}

.indiaJoy__mainSection__item {
  width: 300px;
  height: auto;
  text-align: center;
}

.indiaJoy__rightPanel__container {
  position: absolute;
  top: 80px;
  right: 0px;
}

.indiaJoy__rightPanel__section {
  width: 38px;
  height: 38px;
  margin: 14px 10px;
  cursor: pointer;
}

.indiaJoy__rightPanel__section > img {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.indiaJoy__play__btn {
  color: #fff;
  display: flex;
  border: 1px solid #fff;
  background-color: transparent;
  border-radius: 20px;
  padding: 4px 20px;
  align-items: center;
  cursor: pointer;
  font-size: 14px;
  position: absolute;
  right: 134px;
}

.indiaJoy__play__btn img {
  width: 12px;
  height: 16px;
  margin-left: 10px;
}

.indiaJoy__count__details h2 {
  font-style: normal;
  font-weight: 700;
  font-size: 45px;
  color: #ffffff;
}

.indiaJoy__count__details p {
  font-size: 18px;
  line-height: 26px;
  color: #ffffff;
}

.indiaJoy__powering__wrapper {
  width: 85%;
  height: auto;
  margin: auto;
  position: relative;
}

.indiaJoy__count__details {
  margin: 0px 10px;
}

.indiaJoy__powering__container {
  position: absolute;
  top: 382px;
  width: 100%;
  height: auto;
  text-align: center;
  color: #fff;
}

.indiaJoy__powering__container h2 {
  font-style: normal;
  font-weight: 900;
  font-size: 38px;
  line-height: 51px;
  text-align: center;
  background: linear-gradient(90deg, #ffd300 -61.33%, #fe2fba 147.42%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  text-transform: uppercase;
}

.indiaJoy__powering__container p {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  padding-top: 16px;
  line-height: 28px;
  text-align: center;
  color: #ffffff;
}

.indiaJoy__gamingSection__wraper {
  width: 85%;
  margin: auto;
  height: auto;
  position: relative;
  top: 635px;
}

.indiaJoy__gamingSection__container {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  margin-bottom: 35px;
}

.indiaJoy__gamingSection__items {
  background-image: url("../images/Hud\ Shape.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  width: 561px;
  height: 457px;
  padding: 15px;
  color: #fff;
  position: relative;
}

.indiaJoy__gamingSection {
  display: flex;
}

.indiaJoy__venu__container {
  width: 90%;
}

.indiaJoy__suggestions {
  position: absolute;
  bottom: 50px;
}

.indiaJoy__gamingSection__items h2 {
  font-size: 28px;
  text-transform: uppercase;
  font-weight: 700;
  position: relative;
  top: 6px;
  left: 16px;
  background: linear-gradient(
    132.05deg,
    #ffd300 4.23%,
    #fe2fba 47.3%,
    #030068 100.03%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.indiaJoy__topTitle {
  margin-top: 80px !important;
}

.indiaJoy__text {
  font-size: 16px;
  margin-left: 20px;
  padding-top: 15px;
  line-height: 25px;
}

.indiaJoy__text a {
  color: #fff;
  text-decoration: none;
}

.indiaJoy__leftItem {
  padding-left: 10px;
}

.indiaJoy__leftItem img {
  margin-top: 15px;
  width: 150px;
  height: 150px;
}

.indiaJoy__suggestions button {
  padding: 4px 8px;
  background-color: transparent;
  color: #fff;
  border-radius: 20px;
  border: 1px solid #fe2fba;
  margin: 4px;
  font-size: 13px;
}

.indiaJoy__text button {
  padding: 10px 30px;
  border: 0px;
  border-radius: 20px;
  background: linear-gradient(90deg, #fe2fba 0%, #ffd300 100%);
  color: #fff;
  margin: 10px 0px;
  font-size: 14px;
  text-transform: uppercase;
}

.indiaJoy__text button:hover {
  background: linear-gradient(to right, #feb47b, #fe31b8);
}

.indiaJoy__date {
  float: right;
  position: absolute;
  right: 30px;
  bottom: 20px;
  font-size: 18px;
  color: #ffd300;
}

.indiaJoy__innovation__container {
  background-color: #000000;
  height: auto;
  position: relative;
}

.indiaJoy__innovation__section {
  display: flex;
  justify-content: space-between;
  color: #fff;
  width: 85%;
  height: auto;
  margin: auto;
  padding: 30px 0px;
  align-items: center;
}

.indiaJoy__innovation__innerSection {
  width: 90%;
  height: auto;
  margin: auto;
}

.indiaJoy__innerstyle {
  padding: 30px 0px;
}

.indiaJoy__innovation__innerwraper {
  /* display: flex;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%; */
}

.indiaJoy__slick__slider {
  display: flex !important;
  justify-content: space-between !important;
  flex-wrap: wrap !important;
}

.indiaJoy__innovation__innerItems {
  padding: 15px;
  text-align: center;
  text-align: -webkit-center !important;
}

.indiaJoy__innovation__innerItems h2 {
  text-transform: uppercase;
  color: #fff;
  font-size: 18px;
  padding-top: 20px;
}

.indiaJoy__innovation__title h2 {
  font-weight: 700;
  font-size: 38px;
  line-height: 46px;
  text-transform: uppercase;
  color: #ffffff;
  margin-bottom: 28px;
}

.indiaJoy__innovation__title p {
  font-size: 18px;
}

.india__innovation__itemSection img {
  width: 467px;
  height: auto;
  position: relative;
  top: -30px;
}

.react-responsive-modal-modal {
  width: 95% !important;
  height: 90%;
  max-width: none !important;
  display: inline-block;
  text-align: left;
  vertical-align: middle;
  background: #000 !important;
  box-shadow: 0 12px 15px 0 rgba(0, 0, 0, 0.25);
  margin: 1.2rem;
  position: relative;
  overflow-y: auto;
  box-shadow: rgb(57, 57, 57) 0px 4px 12px;
  top: 20px;
}

.indaiJoy__modal__lg {
  width: 100%;
  height: 100%;
}

.indiaJoy__bannerInfo__vedio {
  width: 100%;
  height: 100%;
}

.react-responsive-modal-closeButton {
  background: #fff;
  border-radius: 50px;
  border: 1px solid #000;
}

/* contact styles */
.indiaJoy__contact__wraper {
  width: 93%;
  height: auto;
  margin: auto;
  padding: 30px 0px 30px 0px;
  margin-top: 60px;
}

.indiaJoy__contact {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.indiaJoy__contact__left {
  width: 100%;
  margin-bottom: 30px;
}

.indiaJoy__contact__left h2 {
  font-size: 50px;
  font-weight: bold;
  background-image: linear-gradient(
    132.05deg,
    #ffd300 -2.77%,
    #fe2fba 22.3%,
    #030068 34.3%
  );
  background-color: #ffd300;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  padding: 20px 0px 40px 0px;
  text-transform: uppercase;
  font-weight: 800;
}

.indiaJoy__contact__section {
  width: 100%;
  display: flex;
  padding: 10px;
  flex-wrap: wrap;
  justify-content: center;
}

.indiaJoy__contact__items1 {
  width: 400px;
  color: #fff;
  margin: 30px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  border: 3px solid transparent;
  background-image: linear-gradient(#000, #000),
    repeating-linear-gradient(-5deg, #f7b733, #fe31b8, #290774);
  background-origin: border-box;
  background-clip: content-box, border-box;
  border-radius: 4px;
}

.submit-button {
  border: 2px solid red;
  transition: border-color 0.3s ease;
}

.submit-button.submitting {
  border-color: red;
}

.error-message {
  color: red;
  font-size: 14px;
  text-align: left;
  padding-top: 8px;
}

.formdetailstx {
  color: #b1b1b1;
}

.indiaJoy__contact__detailsInnerChild {
  padding: 20px 20px 0px 20px;
}

.indiaJoy__contact__right {
  width: 80%;
  margin: auto;
  display: block;
  height: auto;
  color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  border: 5px solid transparent;
  background-image: linear-gradient(#000, #000),
    repeating-linear-gradient(-5deg, #f7b733, #fe31b8, #290774);
  background-origin: border-box;
  background-clip: content-box, border-box;
  border-radius: 4px;
}

.indiaJoy__contact__right form {
  width: 100%;
  height: auto;
  text-align: center;
}

.indiaJoy__contact__formDetails {
  display: flex;
  justify-content: space-between;
}

.indiaJoy__contact__formDetails1 {
  width: 50%;
  padding: 20px;
  label {
    margin-top: 10px;
  }
}

.indiaJoy__contact__formDetails1 input {
  padding: 10px;
  /* background: linear-gradient(
    180deg,
    rgba(12, 12, 18, 0) 0%,
    rgba(12, 12, 18, 0.2) 73.74%
  );  
  opacity: 0.2; */
  background-color: transparent;
  border: 2px solid #333 !important;
  border-radius: 2px;
  color: #ffffff !important;
}

.indiaJoy__contact__formDetails2 {
  width: 50%;
  padding: 20px;
  label {
    margin-top: 10px;
  }
}

.indiaJoy__contact__formDetails2 label,
.indiaJoy__contact__formDetails2 input,
.indiaJoy__contact__formDetails2 select,
.indiaJoy__contact__formDetails2 textarea {
  width: 100%;
}

.indiaJoy__contact__formDetails2 textarea {
  height: 50%;
  padding: 10px;
  /* background: linear-gradient(
    180deg,
    rgba(12, 12, 18, 0) 0%,
    rgba(12, 12, 18, 0.2) 73.74%
  ); */
  background-color: transparent;
  /* opacity: 0.2; */
  border: 2px solid #333 !important;
  border-radius: 2px;
  color: #ffffff !important;
}

.indiaJoy__contact__formDetails2 select {
  padding: 10px;
  /* background: linear-gradient(
    180deg,
    rgba(12, 12, 18, 0) 0%,
    rgba(12, 12, 18, 0.2) 73.74%
  );
  opacity: 0.2; */
  background-color: transparent;
  border: 2px solid #333 !important;
  border-radius: 2px;
  color: #ffffff !important;
}

.indiaJoy__contact__formDetails2 select option {
  color: #000 !important;
  padding: 4px;
}

.indiaJoy__contact__right form label {
  padding: 20px 0px 5px 0px;
  text-align: left;
}

.indiaJoy__contact__right form input {
  padding: 10px;
  /* background: linear-gradient(
    180deg,
    rgba(12, 12, 18, 0) 0%,
    rgba(12, 12, 18, 0.2) 73.74%
  );
  opacity: 0.2; */
  background-color: transparent;
  border: 2px solid #333 !important;
  border-radius: 2px;
  color: #ffffff !important;
}

.indiaJoy__contact__right form input::placeholder {
  color: #ffffff;
}

.indiaJoy__contact__right form input:focus {
  outline: 0px;
}

.indiaJoy__contact__right label,
.indiaJoy__contact__right input {
  width: 100%;
}

.indiaJoy__contact__right button {
  width: 257px;
  height: 55px;
  background: linear-gradient(269.54deg, #ffd100 -3.34%, #fa2290 77.84%);
  color: #fff;
  border: 0px;
  border-radius: 4px;
  margin: 20px 10px 26px 20px;
}

.indiaJoy__contact__items1 h3 {
  font-size: 25px;
  text-transform: uppercase;
}

.indiaJoy__contact__inner {
  padding: 10px 10px 0px 0px;
}

.indiaJoy__contact__inner img {
  padding-bottom: 20px;
  padding-right: 20px;
}

.indiaJoy__contact__inner p {
  position: relative;
  top: -1px;
  font-size: 18px;
}

.indiaJoy__comingSoon__wraper {
  background-image: url("../images/Rectangle\ 180.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  width: 100%;
  height: 554px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.indiaJoy__comingSoon__container {
  width: 90%;
  height: auto;
  margin: auto;
}

.indiaJoy__comingSoon__container h2 {
  color: #fff;
  font-size: 50px;
  text-transform: uppercase;
  font-weight: 700;
}

/* Agenda styles */
.indiaJoy__agenda__wrapper {
  width: 93%;
  height: auto;
  margin: auto;
  color: #fff;
  padding: 0px 0px 40px 0px;
  margin-top: 75px;
}

.indiaJoy__agenda__wrapper h3 {
  font-size: 40px;
  line-height: 44px;
  background-image: linear-gradient(
    132.05deg,
    #ffd300 45.23%,
    #fe2fba 49.3%,
    #030068 65.03%
  );
  background-color: #ffd300;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display: flex;
  justify-content: center;
  padding: 60px 0px 10px 0px;
  text-transform: uppercase;
  font-weight: 800;
}

.indiaJoy__agenda__grid {
  background-image: url("../images/agendaBg.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  width: 336px;
  height: 170px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 20px;
}

.indiaJoy__customGrid {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  border: 5px solid transparent;
  background-image: linear-gradient(#000, #000),
    repeating-linear-gradient(-5deg, #f7b733, #fe31b8, #290774);
  background-origin: border-box;
  background-clip: content-box, border-box;
}

.indiaJoy__agenda__container {
  flex-wrap: wrap;
  display: flex;
  justify-content: center;
  width: 100%;
}

.indiaJoy__agenda__sections button {
  position: absolute;
  bottom: -10px;
  left: 98px;
  border: 0px;
  background: transparent;
}

.indiaJoy__agenda__container h4 {
  font-size: 28px;
  line-height: 53px;
  background-image: linear-gradient(
    132.05deg,
    #ffd300 25.23%,
    #fe2fba 47.3%,
    #030068 75.03%
  );
  background-color: #ffd300;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display: flex;
  justify-content: center;
  text-transform: uppercase;
  font-weight: 800;
}

.indiaJoy__agenda__items {
  position: relative;
  padding: 10px;
  margin: 45px 45px 0px 45px;
}

/* speaker styles */
.indiaJoy__speakers__wrapper {
  width: 93%;
  height: auto;
  margin: auto;
  padding-top: 75px;
  /* border:1px solid white; */
}

.indiaJoy__speakers__wrapper h2 {
  font-size: 50px;
  font-weight: bold;
  /* background-image: linear-gradient(132.05deg, #FFD300 32.2%, #FE2FBA 22.3%, #030068 70.3%);
    background-color: #FFD300; */
  background: -webkit-linear-gradient(#f3a400 30%, #f904a5 81%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  padding: 40px 0px 40px 0px;
  display: flex;
  justify-content: center;
  text-transform: uppercase;
  font-weight: 700;
}

.indiaJoy__speakers__container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.indiaJoy__speakers__section {
  width: 300px;
  /* border:1px solid white; */
  color: #fff;
  text-align: center;
  margin: 20px 30px 20px 30px;
  /* padding: 20px; */
  position: relative;
  /* border: 1px solid #fff; */
}

.indiaJoy__speakers__section h3 {
  font-size: 23px;
  margin-top: 16px;
  text-transform: uppercase;
  font-weight: 700;
}

.indiaJoy__speakers__section p {
  font-size: 16px;
  margin-bottom: 10px;
}

.indiaJoy__speakers__section .speaker_link {
  margin-top: 50px;
}

.indiaJoy__speakers__section .speaker_link a {
  position: absolute;
  display: block;
  bottom: 0px;
  left: 45%;
}

/* past Edition styles */
.indiaJoy__pastEdition__wrapper {
  width: 93%;
  height: auto;
  margin: auto;
  padding-top: 75px;
}

.indiaJoy__pastEdition__wrapper h2 {
  font-size: 45px;
  line-height: 46px;
  background-image: linear-gradient(
    132.05deg,
    #ffd300 4.23%,
    #fe2fba 47.3%,
    #030068 100.03%
  );
  background-color: #ffd300;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display: flex;
  justify-content: left;
  padding: 40px 0px 10px 0px;
  text-transform: uppercase;
  font-weight: 800;
}

.indiaJoy__pastEdition__container {
  display: flex;
  justify-content: space-between;
  align-items: end;
  flex-wrap: wrap;
}

.indiaJoy__pastEdition__item1 {
  width: 700px;
}

.indiaJoy__pastEdition__item1 h3 {
  font-size: 28px;
  line-height: 44px;
  background-image: linear-gradient(
    132.05deg,
    #ffd300 85.23%,
    #fe2fba 47.3%,
    #030068 105.03%
  );
  background-color: #ffd300;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display: flex;
  justify-content: end;
  text-transform: uppercase;
  font-weight: 700;
}

.indiaJoy__pastEdition__item2 {
  width: 700px;
}

.indiaJoy__pastEdition__item2 img {
  width: 100%;
  height: auto;
}

.indiaJoy__pastEdition__card {
  width: 100%;
  height: 400px;
  background: #d9d9d9;
  border-radius: 4px;
  overflow: hidden;
}

.indiaJoy__pastEdition__card iframe,
.indiaJoy__pastEdition__card2 iframe {
  width: 100%;
  height: 100%;
}

.indiaJoy__pastEdition__section {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin: 40px 0px 0px 0px;
}

.indiaJoy__pastEdition__item3 {
  width: 400px;
  margin: 20px;
}

.indiaJoy__pastEdition__item3 h2 {
  font-size: 28px;
  background-image: linear-gradient(
    132.05deg,
    #ffd300 12.23%,
    #fe2fba 47.3%,
    #030068 143.03%
  );
  background-color: #ffd300;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display: flex;
  justify-content: end;
  padding: 0px;
  text-transform: uppercase;
  font-weight: 700;
}

.indiaJoy__pastEdition__card2 {
  width: 100%;
  height: 200px;
  /* background: #d9d9d9; */
  border-radius: 4px;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  /* border: 5px solid transparent; */
  /* background-image: linear-gradient(#000, #000), repeating-linear-gradient(-5deg, #f7b733, #fe31b8, #290774); */
}

/* About styles */
.indiaJoy__about__wrapper {
  width: 93%;
  height: auto;
  margin: auto;
  /* border:1px solid #fff; */
}

.indiaJoy__about__container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.indiaJoy__about__section1 {
  width: 50%;
  /* border:1px solid #fff; */
}

.indiaJoy__about__section1 h2 {
  font-size: 40px;
  line-height: 44px;
  background-image: linear-gradient(
    132.05deg,
    #ffd300 4.23%,
    #fe2fba 47.3%,
    #030068 100.03%
  );
  background-color: #ffd300;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display: flex;
  margin-bottom: 20px;
  text-transform: uppercase;
  font-weight: 800;
  padding-top: 60px;
}

.indiaJoy__about__section1 p {
  font-size: 18px;
  color: #fff;
  text-align: justify;
  line-height: 30px;
}

.indiaJoy__about__section1 button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px;
  width: 187px;
  height: 51px;
  font-size: 15px;
  border: 0px;
  border-radius: 2px;
  font-weight: 500;
  background: linear-gradient(90deg, #fe2fba 0%, #ffd300 100%);
  text-transform: uppercase;
  color: #fff;
  margin: 20px 0px 0px 0px;
}

.indiaJoy__about__section2 {
  width: 600px;
  /* border:1px solid #fff; */
}

.indiaJoy__about__section2 img {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.indiaJoy__about__items .title1 {
  font-size: 40px;
  line-height: 44px;
  background-image: linear-gradient(
    132.05deg,
    #ffd300 4.23%,
    #fe2fba 47.3%,
    #030068 100.03%
  );
  background-color: #ffd300;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display: flex;
  margin-bottom: 30px;
  justify-content: center;
  text-transform: uppercase;
  font-weight: 800;
}

.indiaJoy__about__items .title2 {
  font-size: 40px;
  line-height: 44px;
  background-image: linear-gradient(
    132.05deg,
    #ffd300 4.23%,
    #fe2fba 47.3%,
    #030068 100.03%
  );
  background-color: #ffd300;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display: flex;
  margin: 30px 0px;
  justify-content: center;
  text-transform: uppercase;
  font-weight: 800;
}

.indiaJoy__govt__container {
  background-image: url("../images/GovtBanner.svg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  width: 100%;
  height: auto;
  padding: 50px 0px;
}

.indiaJoy__govt__container1 {
  background-image: url("../images/img.svg");
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  background-position: center center;
  width: 100%;
  height: auto;
  padding: 100px 0px;
  margin: 0px 0px 90px 0px;
}

.indiaJoy__aboutusGovt__title {
  width: 90%;
  height: auto;
  margin: auto;
  text-transform: uppercase;
  font-weight: 800;
}

.indiaJoy__aboutusGovt__title h1 {
  padding: 50px 0px;
  font-weight: 700;
}

.indiaJoy__govt__container {
  color: #fff;
}

.indiaJoy__govt__section {
  width: 100%;
  height: auto;
  margin: auto;
}

.indiaJoy__govt__items {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.indiaJoy__govtItems {
  width: 525px;
  margin: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  border: 5px solid transparent;
  background-image: linear-gradient(#000, #000),
    repeating-linear-gradient(-5deg, #f7b733, #fe31b8, #290774);
  background-origin: border-box;
  background-clip: content-box, border-box;
  font-size: 18px;
}

.indiaJoy__gridFlex {
  display: flex;
}

.indiaJoy__gridFlex img,
.indiaJoy__gridFlex .indiaJoy__grids {
  padding: 20px;
}

.indiaJoy__grids h3 {
  font-size: 20px;
  text-transform: uppercase;
  font-weight: 700;
  background-image: linear-gradient(
    132.05deg,
    #ffd300 4.23%,
    #fe2fba 47.3%,
    #030068 100.03%
  );
  background-color: #ffd300;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.indiaJoy__grids p {
  font-size: 16px;
}

.indiaJoy__grid__contancts {
  margin: 0px 20px 20px 20px;
  font-size: 16px;
}

.indiaJoy__Digital__wrapper {
  width: 90%;
  height: auto;
  margin: auto;
  color: #fff;
  text-align: center;
}

.indiaJoy__Digital__wrapper h2 {
  font-size: 40px;
  text-transform: uppercase;
  font-weight: 700;
}

.indiaJoy__Digital__wrapper p {
  font-size: 16px;
  padding-top: 10px;
}

.indiaJoy__Digital__section {
  display: flex;
  text-align: center;
  justify-content: center;
}

.indiaJoy__Digital__section .indiaJoy__Digital__grids {
  margin: 20px;
}

.indiaJoy__Digital__section .indiaJoy__Digital__grids p {
  font-weight: 700;
}

.indiaJoy__Digital__wrapper h4 {
  background-image: linear-gradient(
    132.05deg,
    #ffd300 4.23%,
    #fe2fba 47.3%,
    #030068 100.03%
  );
  background-color: #ffd300;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 20px;
  padding: 30px 0px;
  font-weight: 700;
}

.amd-gameon img{
  height:100px;
  width: 80px !important;
}

/* tab */
@media screen and (max-width: 1350px) {
  .indiaJoy__gamingSection__items {
    width: 541px;
    height: 442px;
  }

  .indiaJoy__text {
    font-size: 16px;
  }

  /* .indiaJoy__mainSection__wrapper {
    height: 2751px; old
  } */
  .indiaJoy__mainSection__wrapper {
    height: 2620px;
  }
  .indiaJoy__secondSection {
    width: 48%;
    position: inherit !important;
  }

  .venue {
    display: block;
  }

  .venueCard {
    width: 100%;
  }

  .venueCard:last-child {
    margin-top: 40px;
  }
}

@media screen and (max-width: 1300px) {
  .indiaJoy__gamingSection__items {
    width: 503px;
    height: 408px;
  }

  .indiaJoy__text {
    font-size: 14px;
    padding-top: 0px;
  }

  .indiaJoy__suggestions button {
    font-size: 12px;
  }

  .indiaJoy__suggestions {
    bottom: 30px;
  }

  .indiaJoy__date {
    right: 20px;
    bottom: 10px;
  }

  .indiaJoy__about__section2 {
    width: 520px;
    /* border: 1px solid #fff; */
  }
}

@media screen and (max-width: 1120px) {
  .indiaJoy__about__section1 {
    width: 100%;
    margin-top: 50px;
  }

  .indiaJoy__about__section2 {
    width: 540px;
    margin: auto;
  }
}

@media screen and (max-width: 1200px) {
  .indiaJoy__suggestions {
    bottom: 48px;
  }

  .indiaJoy__date {
    right: 29px;
    bottom: 13px;
  }

  .indiaJoy__gamingSection__container {
    display: block;
    justify-content: center;
    margin-bottom: 0px;
  }

  .indiaJoy__gamingSection__items {
    margin: auto;
    width: 589px;
    height: 481px;
  }

  .indiaJoy__text {
    font-size: 16px;
    line-height: 25px;
    padding-top: 10px;
  }

  .indiaJoy__suggestions button {
    font-size: 13px;
  }

  /* .indiaJoy__mainSection__wrapper {
    height: 4958px; old
  } */
  .indiaJoy__mainSection__wrapper {
    height: 4160px;
}

  .purchaseTicketCardBg {
    width: 90%;
  }
}

@media screen and (max-width: 1235px) {
  .indiaJoy__bannerInfo {
    margin-top: -34px;
    margin-left: 0px;
  }
}

@media screen and (max-width: 1020px) {
  .indiaJoy__mainSection__container {
    top: 241px;
  }
}

@media screen and (max-width: 980px) {
  .indiaJoy__innovation__title h2 {
    font-size: 35px;
    line-height: 40px;
  }

  .india__innovation__itemSection img {
    width: 417px;
  }

  .indiaJoy__bannerInfo {
    margin-top: -75px;
  }

  .buyTickets {
    height: 537px;
  }

  .indiaJoy__contact__section {
    width: 100%;
  }

  .indiaJoy__contact__items1 {
    width: 100%;
  }

  .indiaJoy__agenda__items {
    margin: 45px 0px 0px 0px;
  }

  .indiaJoy__banner__content {
    font-size: 30px;
    line-height: 38px;
  }
}

@media screen and (max-width: 768px) {
  .indiaJoy__innovation__section {
    display: block;
  }

  .india__innovation__itemSection img {
    width: 318px;
    position: absolute;
    top: -140px;
    right: 78px;
    transform: rotate(-74deg);
  }

  .indiaJoy__innovation__title h2 {
    font-size: 28px;
    line-height: 36px;
  }

  .indiaJoy__innovation__title p {
    font-size: 16px;
  }

  .indiaJoy__bannerInfo {
    margin-top: -75px;
    height: 639px;
  }

  .agenda-content {
    display: block;
  }

  .indiaJoy__leftSection,
  .indiaJoy__secondSection {
    width: 100%;
  }

  .indiaJoy__tribeSection h2 {
    font-size: 28px;
    line-height: 36px;
  }

  .indiaJoy__leftSection h2 {
    font-size: 28px;
    line-height: 40px;
  }

  .indiaJoy__leftSection {
    padding-top: 0px;
  }

  .agenda-title {
    font-size: 28px;
    line-height: 36px;
    margin-top: 30px;
  }

  .poweredby{
    margin-top: -62px !important;
  }

.poweredbyamd{
  margin-bottom: 40px !important;
}

  .agenda-title__contact {
    font-size: 28px;
    margin-top: 0px;
    padding-bottom: 10px;
  }

  .purchaseTicketCard {
    display: block;
  }

  .address-content {
    width: 100%;
  }

  .footer-title {
    font-size: 28px;
    line-height: 36px;
    margin-top: 30px;
  }

  .address-content {
    font-size: 15px;
  }

  .indiaJoy__innovation__section {
    padding: 0px;
  }

  .indiaJoy__innovation__innerwraper {
    padding-top: 50px;
  }

  .coming-soon-card {
    width: 100%;
  }

  .indiaJoy__contact__left h2 {
    font-size: 35px;
    line-height: 40px;
  }

  .indiaJoy__contact__items1 h3 {
    font-size: 20px;
  }

  .indiaJoy__contact__inner p {
    font-size: 16px;
  }

  .indiaJoy__comingSoon__container h2 {
    font-size: 40px;
  }

  .indiaJoy__agenda__wrapper h3 {
    font-size: 35px;
  }

  .indiaJoy__agenda__container h4 {
    font-size: 25px;
  }

  .indiaJoy__speakers__wrapper h2 {
    font-size: 35px;
  }

  .indiaJoy__about__section1 h2 {
    font-size: 35px;
  }

  .indiaJoy__banner__detailsSection {
    left: 82px;
  }

  .indiaJoy__about__items .title1,
  .indiaJoy__about__items .title2,
  .indiaJoy__aboutusGovt__title h1 {
    font-size: 28px;
  }

  .indiaJoy__Digital__wrapper h2 {
    font-size: 28px;
  }

  .carousel-inner .quotes {
    font-size: 30px;
    position: absolute;
    top: 0px;
  }
  .indiaJoy__global__section h1 {
    font-size: 30px;
    line-height: 36px;
  }
  .indiaJoy__global__details h2,
  .indiaJoy__global__itemList h2,
  .indiaJoy__global__applySection h2 {
    font-size: 25px;
  }
}

@media screen and (max-width: 756px) {
  .indiaJoy__powering__container h2 {
    font-size: 35px;
    line-height: 44px;
  }

  .indiaJoy__banner__detailsSection {
    position: absolute;
    left: 40px;
    top: 50px;
    display: block;
  }

  .indiaJoy__play__btn {
    margin-top: 20px;
    width: 86px;
  }

  .indiaJoy__banner__container {
    height: 492px;
  }

  .indiaJoy__count__details h2 {
    font-size: 36px !important;
  }

  .indiaJoy__count__details p {
    font-size: 16px;
  }

  .indiaJoy__mainSection__container {
    width: 100%;
  }

  .indiaJoy__mainSection__container {
    width: 100%;
  }

  .speakers__content__container h2 {
    font-size: 28px;
    line-height: 36px;
  }

  .explore-button {
    width: 145px;
    height: 44px;
    font-size: 13px;
  }

  .explore-button1 {
    font-size: 13px;
    width: 177px;
    height: 42px;
    padding: 0px 0px;
  }

  .indiaJoy__play__btn {
    position: static;
    right: 0px;
  }

  .secondchild {
    display: block !important;
  }

  .indiaJoy__slider__img {
    margin: 20px 20px 0px 30px;
  }
}

@media screen and (max-width: 650px) {
  .indiaJoy__count__container {
    background-image: url("../images/Vector\ \(1\).webp");
    top: -85px;
  }

  .indiaJoy__count__section {
    top: 120px;
  }

  .indiaJoy__mainSection__container {
    top: 397px;
  }

  .indiaJoy__count__details {
    margin: 13px 10px;
  }

  .indiaJoy__powering__container {
    top: 467px;
  }

  .indiaJoy__gamingSection__wraper {
    top: 716px;
  }

  .indiaJoy__gamingSection__items {
    margin: auto;
    width: 512px;
    height: 415px;
  }

  .indiaJoy__text {
    font-size: 16px;
  }

  .indiaJoy__gamingSection__items h2 {
    font-size: 25px;
  }

  .indiaJoy__text {
    font-size: 14px;
    line-height: 22px;
    padding-top: 10px;
  }

  .indiaJoy__text button {
    font-size: 12px;
    padding: 8px 30px;
  }

  .indiaJoy__suggestions button {
    font-size: 12px;
  }

  /* .indiaJoy__mainSection__wrapper {
    height: 4662px; old
  } */
  .indiaJoy__mainSection__wrapper {
    height: 4040px;
}

  .image-gallery-left-nav .image-gallery-svg,
  .image-gallery-right-nav .image-gallery-svg {
    height: 25px !important;
    width: 35px !important;
  }

  .venue-inner-card {
    margin-right: 0px;
    padding: 25px 0px 25px 20px;
  }

  .indiaJoy__bannerInfo {
    margin-top: -127px;
    height: 718px;
    width: 781px;
  }

  .indiaJoy__novotelSection p {
    font-size: 14px;
  }

  .locationImg {
    width: 195px;
    height: 148px;
  }

  .locationLogo {
    width: 53px;
    height: 67px;
    margin-right: 10px;
  }

  .subscribe-content {
    display: block;
  }

  /* .emailInput {
    width: 100%;
  } */

  .subscribe_input {
    width: 100%;
  }

  .emailInput {
    background-color: #fff;
    width: 98%;
    /* height: 25px; */
    display: inline-block;
    border-radius: 30px;
    height: 47px;
    background-color: #000;
    margin-left: 3px;
    margin-top: 4px;
    padding-left: 10px;
  }

  .subscribe-button {
    width: 100%;
    margin: 10px 0px;
  }

  .india__innovation__itemSection img {
    width: 318px;
    position: absolute;
    top: -296px;
    right: 78px;
    transform: rotate(-74deg);
  }

  .indiaJoy__contact {
    display: block;
  }

  .indiaJoy__contact__right {
    width: 100%;
  }

  .indiaJoy__banner__content {
    font-size: 20px;
    line-height: 28px;
  }
}

@media screen and (max-width: 580px) {
  .indiaJoy__leftItem img {
    width: 100px;
    height: 100px;
  }

  .indiaJoy__suggestions {
    bottom: 36px;
  }

  .indiaJoy__text {
    font-size: 14px;
  }

  .indiaJoy__gamingSection__items {
    margin: auto;
    width: 449px;
    height: 368px;
  }

  .indiaJoy__suggestions button {
    font-size: 10px;
  }

  .indiaJoy__date {
    font-size: 15px !important;
  }

  .indiaJoy__gamingSection__items h2 {
    font-size: 19px;
  }

  .indiaJoy__text button {
    padding: 2px 20px;
  }

  /* .indiaJoy__mainSection__wrapper {
    height: 4288px; old
  } */

  .indiaJoy__mainSection__wrapper {
    height: 3740px;
}

  .speakers-content {
    display: block;
  }

  .speakers__content__container,
  .speaker__content__section {
    width: 100%;
  }

  .indiaJoy__pastEdition__item2 {
    display: none;
  }

  .indiaJoy__pastEdition__wrapper h2 {
    font-size: 30px;
    line-height: 37px;
  }

  .indiaJoy__pastEdition__item3 h2 {
    font-size: 20px;
  }
}
.customStyle{
  padding-top: 10px;
  font-size: 25px;
}
/* mobile */
@media screen and (max-width: 500px) {
  .indiaJoy__banner__details > .customStyleCon > img {
    width: 158px;
    height: 35px;
  }

  .indiaJoy__banner__detailsSection {
    position: absolute;
    left: 40px;
    top: 73px;
  }

  .indiaJoy__banner__content {
    font-size: 20px;
    margin-top: 7px;
    line-height: 25px;
  }

  .indiaJoy__banner__subText > span {
    font-size: 25px !important;
  }

  .indiaJoy__banner__subText,
  .indiaJoy__banner__text {
    font-size: 15px !important;
  }

  .indiaJoy__banner__subText {
    padding-top: 15px;
  }

  .indiaJoy__banner__text {
    line-height: 10px;
  }

  .indiaJoy__banner__btn {
    width: 162px;
    height: 44px;
    font-size: 14px;
  }

  .indiaJoy__rightPanel__section {
    width: 30px;
    height: 38px;
    margin: 4px;
    cursor: pointer;
  }

  .indiaJoy__banner__container {
    height: 450px;
  }

  .indiaJoy__count__details h2 {
    font-size: 32px !important;
  }

  .indiaJoy__count__details {
    margin: 11px 10px;
    width: 36%;
  }

  .indiaJoy__mainSection__item img {
    width: 65px;
  }

  .customImg img {
    width: 41px !important;
  }

  .indiaJoy__powering__container h2 {
    font-size: 28px;
    line-height: 39px;
  }

  .indiaJoy__powering__container p {
    font-size: 14px;
    padding-top: 9px;
    line-height: 23px;
  }

  .indiaJoy__mainSection__item p {
    font-size: 16px;
  }

  .indiaJoy__gamingSection__items {
    background-image: url("../images/Hud\ Shape\ \(1\).webp");
  }

  .indiaJoy__gamingSection__items {
    margin: auto;
    width: 335px;
    height: 467px;
  }

  .indiaJoy__suggestions {
    position: absolute;
    bottom: 29px;
  }

  .indiaJoy__text {
    font-size: 13px;
  }

  .indiaJoy__date {
    right: 13px;
    bottom: 9px;
    font-size: 12px !important;
  }

  .indiaJoy__gamingSection__wraper {
    top: 669px;
  }

  /* .indiaJoy__mainSection__wrapper {
    height: 4815px; old
  } */
  /* .indiaJoy__mainSection__wrapper {
    height: 4110px;
} */

.indiaJoy__mainSection__wrapper {
  height: 5550px;
}

  .indiaJoy__gamingSection__wraper {
    width: 90%;
  }

  .indiaJoy__innovation__innerItems h2 {
    font-size: 14px;
  }

  .buyTickets {
    height: 345px;
  }

  .indiaJoy__rightPanel__footer {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .indiaJoy__rightPanel__section {
    display: none;
  }

  .indiaJoy__rightPanel__container__menubar {
    display: block;
  }

  .indiaJoy__rightPanel__section__menu {
    display: block;
    display: block;
    margin: 10px;
    padding: 30px 0px 0px 0px;
  }

  .india__innovation__itemSection img {
    width: 244px;
    position: absolute;
    top: -225px;
    right: 54px;
    transform: rotate(30deg);
  }

  .cardBg {
    padding: 0px;
  }

  .indiaJoy__leftItem img {
    margin-top: 5px;
  }

  .indiaJoy__gamingSection {
    display: block;
  }

  .indiaJoy__text {
    line-height: 20px;
    margin-left: 1px;
  }

  .indiaJoy__suggestions button {
    padding: 2px 8px;
  }

  .footer-title {
    margin-top: 0px;
  }

  .indiaJoy__gamingSection__items h2 {
    font-size: 18px;
    left: 0px;
  }

  .indiaJoy__leftItem {
    padding-left: 0px;
  }

  .indiaJoy__leftItem img {
    width: 90px;
    height: 90px;
  }

  .address-content {
    font-size: 14px;
  }

  .footer-content {
    font-size: 13px;
  }

  .description__card {
    font-size: 15px;
  }

  .indiaJoy__comingSoon__wraper {
    height: 343px;
  }

  .indiaJoy__comingSoon__container h2 {
    font-size: 25px;
    padding-top: 10px;
  }

  .indiaJoy__agenda__wrapper h3 {
    font-size: 30px;
    line-height: 0px;
  }

  .indiaJoy__agenda__items {
    margin: 19px 0px 0px 0px;
  }

  .indiaJoy__agenda__container h4 {
    font-size: 22px;
  }

  .indiaJoy__speakers__section h3 {
    font-size: 20px;
  }

  .indiaJoy__about__section1 h2 {
    font-size: 28px;
  }

  .indiaJoy__about__section1 p {
    font-size: 16px;
    text-align: left;
  }

  .indiaJoy__govtItems {
    width: 100%;
  }

  .indiaJoy__about__items .title1,
  .indiaJoy__about__items .title2,
  .indiaJoy__aboutusGovt__title h1 {
    font-size: 23px;
  }

  .indiaJoy__Digital__wrapper h2 {
    font-size: 23px;
  }

  .indiaJoy__contact__formDetails {
    display: block;
  }

  .indiaJoy__contact__formDetails1,
  .indiaJoy__contact__formDetails2 {
    width: 100%;
  }

  .indiaJoy__contact__formDetails1 {
    padding: 20px 20px 0px 20px;
  }

  .indiaJoy__contact__formDetails2 {
    padding: 0px 20px 0px 20px;
  }

  .indiaJoy__contact__wraper {
    padding: 30px 0px 0px 0px;
  }

  .agenda-content {
    padding: 0px;
  }

  .designHeader {
    margin: 30px 0px 0px 0px;
  }

  .indiaJoy__speakers__wrapper h2 {
    font-size: 25px;
  }
  .indiaJoy__global__section h1 {
    font-size: 25px;
    line-height: 30px;
  }
  .indiaJoy__global__details h2,
  .indiaJoy__global__itemList h2,
  .indiaJoy__global__applySection h2 {
    font-size: 20px;
  }
  .indiaJoy__global__container {
    font-size: 16px;
  }
  .indiaJoy__global__container button {
    padding: 10px 48px;
    font-size: 15px;
  }
  .indiaJoy__global__bannerSection img {
    width: 130px;
    height: 160px;
  }
  .customStyle{
    font-size: 18px !important;
    position: relative;
    top: -8px;
    padding-top: 0px;
  }
  .customStyleCon{
    display: grid;
  }

  .amd-gameon img{
    height: 75px !important;
    width: 75px !important;
  }
  
}

@media screen and (max-width: 497px) {
  .indiaJoy__count__details h2 {
    font-size: 25px !important;
  }

  .indiaJoy__count__details p {
    font-size: 14px;
    line-height: 19px;
    margin-bottom: 35px;
  }

  .indiaJoy__powering__container h2 {
    font-size: 24px;
    line-height: 33px;
  }

  .venueCard {
    display: block;
  }

  .venue-inner-card-location {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    padding-bottom: 10px;
  }

  .venue-inner-card {
    padding: 25px 0px 25px 0px;
  }

  .locationImg {
    width: 300px;
    height: 172px;
    position: inherit;
    right: 0;
  }

  .indiaJoy__tribeSection {
    padding: 0px;
  }

  .indiaJoy__tribeSection h2 {
    font-size: 25px;
    line-height: 30px;
  }

  .indiaJoy__tribeSection h2 {
    font-size: 22px;
    line-height: 24px;
  }

  .indiaJoy__leftSection h2 {
    font-size: 20px;
    line-height: 28px;
  }

  .indiaJoy__leftSection p {
    font-size: 16px;
  }

  .description {
    font-size: 16px;
  }

  .speaker-name {
    font-size: 20px;
  }

  .agenda-title,
  .agenda-title__contact {
    font-size: 22px;
    line-height: 33px;
    margin-top: 0px;
  }

  .indiaJoy__topTitle {
    margin-top: 30px !important;
  }

  .indiaJoy__meet__container {
    margin: 0px 0px 25px 0px !important;
    position: relative;
    top: -8px;
  }

  .indiaJoy__contact__items1 h3 {
    font-size: 18px;
  }

  .indiaJoy__gridFlex {
    display: block;
  }
}

@media screen and (max-width: 425px) {
  .indiaJoy__mainSection__container {
    top: 351px;
  }

  .indiaJoy__powering__container {
    top: 431px;
  }
}

.image-gallery-svg {
  width: 60px !important;
  height: 30px !important;
}

.subscribe input {
  color: #fff;
  /* margin-left: 10px; */
}

.subscribe input:focus {
  color: #fff;
  /* border: 0;
  outline: 0; */
}

.form-control:focus {
  box-shadow: unset !important;
}

input,
select,
textarea {
  color: #fff;
}

form .indiaJoy__contact__formDetails1 input {
  color: #fff;
}

.form-control:focus {
  color: #fff !important;
  background-color: black !important;
  border-color: transparent !important;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  border: 1px solid transparent !important;
}

.form-control:hover {
  color: #fff !important;
  background-color: black !important;
  border-color: transparent !important;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  border: 1px solid transparent !important;
}

.form-control input {
  padding-left: 10px !important;
}

.thank-you-message{
  margin-top: 30px !important;
}


.hl-app .multi_select_form .multiselect__select {
  top: 0px !important;
}

.flex-col .email-input{
  max-width: 500px !important;  
  border: 1px solid greenyellow;
}

